import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/LayoutView'
import PropertyListing from '../components/PropertyListing'
import PageHeader from '../components/common/pageHeader'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const PropertyCategoryByCity = ({ data, pageContext }) => {
  const { propertyType, categoryInfo, properties, cities } = data

  const title = `${propertyType.name} ${categoryInfo.name}`

  return (
    <Layout>
      <GatsbySeo
      title={title}
      description='Find out more about us'  
      openGraph={{
        title: {title},
        description: 'Description of open graph article',
        url: 'https://www.example.com/articles/article-title',
        images: [
          {
            url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
            width: 850,
            height: 650,
            alt: 'Og Image Alt Article Title A',
          },
         
          
        ],
        site_name: 'Owning a home does not need to make you lose sleep',
      }}
      twitter={{
        handle: '@ownahome_ng',
        site: '@ownahome.ng',
        cardType: 'summary_large_image',
      }}
    />
      <PageHeader headertitle={title}>
        <ul className='page-list'>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to={`/property/${categoryInfo.slug}`}>
              property {categoryInfo.name}
            </Link>
          </li>
          <li>{propertyType.name}</li>
        </ul>
      </PageHeader>
      <div className='pt-5 pd-bottom-70'>
        <div className='row mb-4'>
          <div className='col-9 offset-md-3'>
            <h6 className='my-4'>Quick Filters</h6>
            <div className='d-flex align-items-center flex-wrap'>
              {cities.nodes.map((city, index) => {
                return (
                  <p className='px-2 border-end title readeal-top' key={index}>
                    <Link
                      to={`/property/${categoryInfo.slug}/${propertyType.slug}/${city.slug}`}
                    >
                      {city.name}
                    </Link>
                  </p>
                )
              })}
            </div>
          </div>
        </div>
        <PropertyListing
          properties={properties}
          pageContext={pageContext}
          pathname={`/property/${categoryInfo.slug}/${propertyType.slug}`}
        />
      </div>
    </Layout>
  )
}

export default PropertyCategoryByCity

export const query = graphql`
  query allPropertyTypeQuery($slug: String!, $category: String!) {
    propertyType: contentfulPropertyCategoryByType(slug: { eq: $slug }) {
      name
      slug
      id
    }

    categoryInfo: contentfulPropertyCategory(slug: { eq: $category }) {
      id
      slug
      name
    }

    cities: allContentfulPropertyCategoryByCity {
      nodes {
        name
        slug
      }
    }

    properties: allContentfulProperty(
      filter: {
        propertyCategory: { slug: { eq: $category } }
        propertyType: { slug: { eq: $slug } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...Property
      }
      ...PropertyPagination
    }
  }
`
